import React, { useEffect } from 'react'
import IO from '../Io'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const ContactForm = () => (
  <form
    name="contact"
    method="POST"
    netlify-honeypot="bot-field"
    data-netlify="true"
    className="column is-8 is-12-mobile contact-form"
    data-animation-scroll="fade-in-left"
    data-animation-delay="300"
  >
    <input type="hidden" name="form-name" value="contact" />
    {['Name', 'Email', 'Company Name', 'Company Size'].map((field, index) => (
      <label key={index}>
        {field}:
        <input
          type={field === 'Email' ? 'email' : 'text'}
          name={field.toLowerCase().replace(' ', '-')}
          id={field.toLowerCase().replace(' ', '-')}
          required
        />
      </label>
    ))}
    <label className="fullwidth">
      Your Message:
      <textarea name="message" id="message" rows="8" required />
    </label>
    <label className="hidden">
      Don’t fill this out if you’re human:
      <input name="bot-field" />
    </label>
    <button type="submit" className="navbar-item work-with-us">
      Send
    </button>
  </form>
)

const ContactContent = ({ title, description, ideasTitle, ideas }) => (
  <div
    className="column is-4 is-12-mobile"
    data-animation-scroll="fade-in-right"
    data-animation-delay="300"
  >
    <h3>{title}</h3>
    <p dangerouslySetInnerHTML={{ __html: description }}></p>
  </div>
)

export const Contact = ({
  title,
  description,
  ideasTitle,
  ideas,
  mobileBg,
  desktopBg
}) => {
  useEffect(() => {
    new IO()

    const BREAKPOINT = 768
    const contactSection = document.getElementById('contact')
    const backgroundImage =
      window.innerWidth <= BREAKPOINT ? mobileBg : desktopBg
    contactSection.style.setProperty(
      '--background-image',
      `url(${backgroundImage})`
    )

    const handleResize = () => {
      const backgroundImage =
        window.innerWidth <= BREAKPOINT ? mobileBg : desktopBg
      contactSection.style.setProperty(
        '--background-image',
        `url(${backgroundImage})`
      )
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [mobileBg, desktopBg])

  return (
    <section className="contact" id="contact" aria-label="Contact Us">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="content">
              <div className="columns is-multiline contact__content">
                <ContactContent
                  title={title}
                  description={description}
                  ideasTitle={ideasTitle}
                  ideas={ideas}
                />
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-bottom-fade"></div>
    </section>
  )
}
