import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import logo from '../img/dyode-logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      activeNavItem: ''
    }
    this.handleLinkClick = this.handleLinkClick.bind(this)
  }

  handleLinkClick = (e, target) => {
    // // NODE-SAFE CODE
    // // Gatsby uses Node to generate our pages.
    // // Node doesn't know what a window is.
    // // Be sure to wrap any of your browser interactions
    // // in some sort of node-safe if statement like this:
    this.setState({ activeNavItem: target })
    // if (typeof window !== 'undefined') {
    //   // First, are we on the home page?
    //   // If so, let's scroll to the desired block,
    //   // which was passed in as an onClick method on our <Link />.
    //   // If an event was also passed, we'll preventDefault()
    //   // debugger
    //   if (window.location.hash && window.location.hash !== '#careers') {
    //     window.location.hash = target
    //     if (e) e.preventDefault()
    //     scrollToElement(target, {
    //       offset: -95, // Offset a fixed header if you please
    //       duration: 1000
    //     })
    //   }
    // }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active'
            })
          : this.setState({
              navBarActiveClass: ''
            })
      }
    )
    document.querySelector('html').classList.toggle('menu--is-active')
    document.querySelector('body').classList.toggle('menu--is-active')
  }

  render() {
    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
        data-animation-scroll="fade-in-up"
      >
        <div className="container">
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <AnchorLink
                className={
                  this.state.activeNavItem === 'our-work'
                    ? 'navbar-item active'
                    : 'navbar-item'
                }
                to="/#our-work"
                content="Our Work"
                stripHash={true}
                onAnchorLinkClick={(e) => this.handleLinkClick(e, 'our-work')}
              >
                Our Work
              </AnchorLink>
              <AnchorLink
                className={
                  this.state.activeNavItem === 'services'
                    ? 'navbar-item active'
                    : 'navbar-item'
                }
                to="/#services"
                content="Services"
                stripHash={true}
                onAnchorLinkClick={(e) => this.handleLinkClick(e, 'services')}
              >
                Services
              </AnchorLink>
            </div>
            <div className="navbar-brand">
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
                onKeyDown={() => this.toggleHamburger()}
              >
                <i>
                  <span />
                  <span />
                  <span />
                </i>
              </div>
              <Link
                to="/"
                className="navbar-item navbar-item--logo"
                title="Logo"
                content="Dyode Logo"
              >
                <img src={logo} alt="DYODE." />
              </Link>
            </div>

            <div className="navbar-end has-text-centered">
              <AnchorLink
                className={
                  this.state.activeNavItem === 'partners'
                    ? 'navbar-item active'
                    : 'navbar-item'
                }
                to="/#partners"
                content="Partners"
                stripHash={true}
                onAnchorLinkClick={(e) => this.handleLinkClick(e, 'partners')}
              >
                Partners
              </AnchorLink>
              <AnchorLink
                className={
                  this.state.activeNavItem === 'contact'
                    ? 'navbar-item work-with-us active'
                    : 'navbar-item work-with-us'
                }
                to="/#contact"
                content="Work with us"
                stripHash={true}
                onAnchorLinkClick={(e) => this.handleLinkClick(e, 'contact')}
              >
                Work with us
              </AnchorLink>
            </div>
            <div className="navbar--mobile">
              <ul>
                <li>
                  <Link
                    className={
                      this.state.activeNavItem === 'services'
                        ? 'navbar-item active'
                        : 'navbar-item'
                    }
                    to="/#services"
                    content="Services"
                    activeClassName="active"
                    onClick={() => this.toggleHamburger()}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      this.state.activeNavItem === 'services'
                        ? 'navbar-item active'
                        : 'navbar-item'
                    }
                    to="/#our-work"
                    content="Our Work"
                    activeClassName="active"
                    onClick={() => this.toggleHamburger()}
                  >
                    Our Work
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      this.state.activeNavItem === 'services'
                        ? 'navbar-item active'
                        : 'navbar-item'
                    }
                    to="/#partners"
                    content="Partners"
                    activeClassName="active"
                    onClick={() => this.toggleHamburger()}
                  >
                    Partners
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      this.state.activeNavItem === 'services'
                        ? 'navbar-item active'
                        : 'navbar-item work-with-us'
                    }
                    to="/contact"
                    content="Wonk with us"
                    activeClassName="active"
                    onClick={() => this.toggleHamburger()}
                  >
                    Work with us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
